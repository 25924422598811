import Circular from "@/components/Circular";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { themeColors } from "@/utils/themeColors";
import { LucideIcon } from "lucide-react";
import Image from "next/image";
import br from "@/styles/breakpoints.module.css";
import { clsx } from "clsx";

export const SidebarPopupTabItem = ({
    children,
    onClick,
    isSelected,
    id,
}: { children: React.ReactNode; isSelected: boolean; onClick: () => void; id: string }) => {
    return (
        <CircularRectTextButton
            id={id}
            className={clsx("secondaryText2", br.smDownWidthAuto)}
            onMouseDown={e => e.preventDefault()}
            onClick={onClick}
            sx={{
                height: "3rem",
                fontSize: "1.3rem",
                justifyContent: "flex-start",
                textAlign: "left",
                padding: "0.2rem 0.8rem",
                borderRadius: "0.7rem",
                gap: "0.5rem",
                backgroundColor: isSelected ? themeColors.primaryLight : "transparent",
                fontWeight: "normal",
                width: "100%",
                color: isSelected ? themeColors.pureBlack : themeColors.neutralBlack,
                "&:hover": {
                    boxShadow: "none",
                },
            }}>
            {children}
        </CircularRectTextButton>
    );
};

export const SidebarPopupTabItemImg = ({
    img,
    alt,
    background,
    icon,
}: { img: string; alt: string; background?: string; icon?: React.ReactNode }) => {
    return (
        <Circular style={{ height: "2rem", width: "2rem", background, borderRadius: "0.5rem" }}>
            {icon || (
                <Image
                    src={img}
                    alt={"get " + alt + " tools"}
                    height="13"
                    width="13"
                    style={{ objectFit: "contain" }}
                />
            )}
        </Circular>
    );
};

export const SidebarPopupTabItemIcon = ({
    Icon,
    alt,
    background,
}: { Icon: LucideIcon; alt: string; background?: string }) => {
    return (
        <Circular style={{ height: "2rem", width: "2rem", background, borderRadius: "0.5rem" }}>
            <Icon alt={alt} size={13} color={themeColors.neutralBlack} />
        </Circular>
    );
};
